<template>
  <Card>
    <template #title>
      {{ $t("applications.downloadAndSign") }}
    </template>

    <template #content>
      <div
        class="flex flex-column align-items-center justify-content-center h-13rem border-solid border-100 mb-5"
      >
        <div class="flex align-items-center justify-content-center h-auto">
          <span
            alt="Download application"
            class="mdi mdi-96px mdi-orange mdi-cloud-download-outline"
          ></span>
        </div>
        <div class="flex align-items-center justify-content-center text-center pb-3">
          {{ $t("applications.pressButtonToDownload") }}
        </div>
        <div class="flex align-items-center justify-content-center text-center">
          <Button
            :label="$t('applications.downloadPdf')"
            class="mr-2 mb-2"
            :loading="loadingDownload"
            iconPos="right"
            @click="downloadPdf"
          />
        </div>
      </div>
    </template>

    <template #footer>
      <div class="text-right">
        <Button
          :label="$t('global.cancel')"
          @click="cancel"
          class="p-button-danger"
        />
        <Button
          :label="$t('global.nextPage')"
          autofocus
          @click="nextPage"
          style="margin-left: 1%; margin-right: 1%"
        />
      </div>
    </template>
  </Card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Download and sign",
  emits: ["next-page"],
  data() {
    return {
      disableNext: true,
      loadingDownload: false,
    };
  },
  computed: {
    ...mapGetters(["getApplicationPdf", "clientInfo"]),
  },
  methods: {
    cancel() {
      this.$router.push({ name: "applications" });
    },
    async nextPage() {
      //       const result = await this.v$.$validate();
      this.submitted = true;
      this.$emit("next-page", { pageIndex: 2, value: 75 });
      //       if (result) {
      //       }
    },
    async downloadPdf() {
      //  delete this.getApplicationPdf.userId;
      //   this.getApplicationPdf.companyName = this.clientInfo.companyName;
      try {
        this.loadingDownload = true;
        await this.$store.dispatch("applicationPdf", this.getApplicationPdf.id).then(() => {
          this.$action("application-download", { applicationId: this.getApplicationPdf.id });
        })
        this.disableNext = false;
        this.loadingDownload = false;
      } catch (err) {
        this.loadingDownload = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// .downloadIcon {
//   // padding-top: 5%;
// }
.col {
  text-align: center;
}

.download {
  // padding-top: 5%;
  // padding-bottom: 2%;
  border-style: solid;
  border-color: #f9f9f9;
}
</style>
